<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/bienestarricotecnologia/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">DOLA IMPORT EXPORT SERVICES TRADING COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Về chúng tôi</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Sản phẩm</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Vấn đề thường gặp</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="Li4"
        >
          <!-- <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Thỏa thuận quyền riêng tư</span>
          </router-link> -->
          <a href="https://don.doladong.com/V9D6d0g/j6Mz2kpa.html" class="nav-link3" target="_blank">
            <span class="routeText4">Thỏa thuận quyền riêng tư</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height:90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #266DDC;
  /* box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10); */
  /* box-shadow: 0px 2px 10px rgba(50, 55, 71, 0.10); */
  /* background-image: url("@/page/bienestarricotecnologia/components/img/NavBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #FFFFFF;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  height: 40px;
  line-height: 40px;
  /* height: 90px;
  line-height: 90px; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #FFFFFF;
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 4px;
  padding-right: 4px;
  padding: 6px;
  height: 40px;
  /* line-height: 90px; */
}
.Li1 {
  width: 150px;
}
.Li2 {
  width: 140px;
}
.Li3 {
  width: 200px;
}
.Li4 {
  width: 260px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.active-link {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  /* border-radius: 40px; */
  /* border-radius: 9px; */
  border-radius: 24px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  color: #6C57DB;
}
.title{
  /* border: 1px solid red; */
  /* padding-left: 30px; */
  width: 630px
}
.secondBox{
  /* padding: 0 50px; */
}
</style>